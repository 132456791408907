import React from 'react'
import PropTypes from 'prop-types'
import loaderLight from '@assets/images/loader-light.svg'
import {
  StoreToCartWrapper,
  StyledP,
  Title,
  Error,
  Input,
  Label,
  Asterisk,
  SubmitButton,
  LookingStoreCartMessage,
} from './styles'

const InputPageContent = ({ email, errorMessage, handleInputChange, handleSubmit, loading, storeCartId }) => {
  const handleClick = ({ key, type }) => {
    if (type === 'click' || key === 'Enter') {
      handleSubmit(storeCartId.trim(), email.trim())
    }
  }

  return (
    <StoreToCartWrapper>
      <Title>Store Cart</Title>
      <StyledP>
        If you have recently visited a showroom and have created a store cart with one of our representatives please
        enter your cart information below.
      </StyledP>
      <StyledP>
        <Asterisk className="asterisk">*</Asterisk>
        <span>= Required field</span>
      </StyledP>
      <Label htmlFor="store-cart-id">
        <span className="asterisk">*</span>
        Store Cart Number
        <Input
          id="store-cart-id"
          name="store-cart-id"
          data-testid="id-input"
          type="text"
          value={storeCartId || ''}
          onChange={handleInputChange}
          onKeyDown={handleClick}
          required
          aria-required="true"
          disabled={loading}
        />
      </Label>
      <Label htmlFor="email">
        <span className="asterisk">*</span>
        Email
        <Input
          id="email"
          name="email"
          data-testid="email-input"
          type="text"
          value={email}
          onChange={handleInputChange}
          onKeyDown={handleClick}
          aria-required="true"
          required
          disabled={loading}
        />
      </Label>
      {errorMessage && <Error>{errorMessage}</Error>}
      <SubmitButton data-testid="submit" type="button" onClick={handleClick}>
        {loading ? <img alt="loading" src={loaderLight} /> : <p>FIND STORE CART</p>}
      </SubmitButton>
      {loading && <LookingStoreCartMessage>Looking for store cart...</LookingStoreCartMessage>}
    </StoreToCartWrapper>
  )
}

InputPageContent.propTypes = {
  email: PropTypes.string,
  errorMessage: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  storeCartId: PropTypes.string,
}

export default InputPageContent
