import React from 'react'
import Helmet from 'react-helmet'
import StoreToCartInput from '@components/store-to-cart-input'
import { getURLParam } from '@helpers/general'
import Layout from '../components/layout'

const StoreToCartInputPage = props => {
  const cartNumberFromUrl = getURLParam('storeCartNo')
  const content = cartNumberFromUrl
    ? `app-id=898852501, app-argument=https://www.roomstogo.com/store-to-cart-input?storeCartNo=${cartNumberFromUrl}`
    : 'app-id=898852501'
  return (
    <Layout {...props} cartPage>
      <Helmet
        title="Store Cart - Rooms To Go"
        meta={
          cartNumberFromUrl && [
            {
              name: 'apple-itunes-app',
              content,
            },
          ]
        }
      />
      <StoreToCartInput />
    </Layout>
  )
}

export default StoreToCartInputPage
