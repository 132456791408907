import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getURLParam } from '@helpers/general'
import { addStoreCartEvent } from '@helpers/google-tag-manager'
import { setShippingAddressInfo, setContactInfo, setStoreInfo } from '@helpers/checkout/shipping-section'
import { navigate } from 'gatsby'
import usePageAnalytics from '@hooks/usePageAnalytics'
import { getStoreCartItem } from '@services/checkout'
import { setStoreCartCreationData } from '@redux/modules/cart'
import { UNABLE_TO_FIND_STORE_CART, FILLOUT_REQUIRED_FIELDS } from './messages'
import InputPageContent from './input-page-content'

const StoreToCart = () => {
  const [storeCartId, setStoreCartId] = useState('')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleInputChange = ({ target }) => {
    if (target.name === 'store-cart-id') {
      setStoreCartId(target.value)
    }
    if (target.name === 'email') {
      setEmail(target.value)
    }
    if (errorMessage) setErrorMessage('')
  }

  const handleSubmit = async (submittedId, submittedEmail) => {
    if (!submittedId || !submittedEmail) {
      setErrorMessage(FILLOUT_REQUIRED_FIELDS)
    } else {
      setErrorMessage('')
      try {
        // async request for the storecart data from the backend
        setLoading(true)
        let response = await getStoreCartItem({ storeCartId: submittedId, email: submittedEmail })

        // If getStoreCartItem returns an object with storeCartArray, then take the latest cart in the array
        if (response.storeCartArray) {
          response = response.storeCartArray[response.storeCartArray.length - 1]
        }

        const { contact, lineItems, shippingAddress } = response
        // if the response lineitems are a valid array, setup the cart and save the response locally
        if (Array.isArray(lineItems) && lineItems.length > 0) {
          // set the user shipping address info and contact info based on the storecart response
          if (contact && shippingAddress) {
            setShippingAddressInfo(shippingAddress)
            setStoreInfo(response)
            setContactInfo(contact)
          }

          dispatch(setStoreCartCreationData({ id: submittedId, email: submittedEmail }))
          addStoreCartEvent(response)
          navigate('/cart')
        } else {
          setLoading(false)
          setErrorMessage('There was an issue with your Store Cart')
        }
      } catch {
        setLoading(false)
        setErrorMessage(UNABLE_TO_FIND_STORE_CART)
      }
    }
  }

  usePageAnalytics({ type: 'store-cart-input-page', title: 'Store Cart Input Page', path: '/store-cart-input-page' })

  useEffect(() => {
    const cartNumberFromUrl = getURLParam('storeCartNo')
    const emailAddressFromUrl = getURLParam('emailAddress')
    if (cartNumberFromUrl) setStoreCartId(cartNumberFromUrl)
    if (emailAddressFromUrl) setEmail(emailAddressFromUrl)

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' })
    }
    if (cartNumberFromUrl && emailAddressFromUrl) {
      // If URL contains store cart ID and email address, the skip the input and immediately validate
      handleSubmit(cartNumberFromUrl, emailAddressFromUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InputPageContent
      email={email}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={loading}
      storeCartId={storeCartId}
    />
  )
}
export default StoreToCart
