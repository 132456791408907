import styled from 'styled-components'

export const StoreToCartWrapper = styled.div`
  padding: 48px;
  max-width: 800px;
`

export const StyledP = styled.p`
  margin: 8px 0;
`

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 24px;
  color: #333;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
`

export const Error = styled.div`
  color: #eb141f;
`

export const Input = styled.input`
  height: 2.2rem;
  display: block;

  && {
    width: 100%;
    max-width: 400px;
  }
`

export const Label = styled.label`
  margin-top: 12px;
  display: block;
`

export const Asterisk = styled.span`
  margin-right: 2px;
`

export const SubmitButton = styled.button`
  background-color: #0053a0;
  border: 0;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.25rem;
  font-weight: 600;
  height: 3rem;
  width: 180px;
  margin-top: 24px;

  & p {
    color: white;
  }

  & img {
    height: 80%;
    width: auto;
  }
`

export const LookingStoreCartMessage = styled.span`
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-top: 1.5rem;
  @media only screen and (max-width: 639px) {
    display: block;
    font-size: 1.4rem;
    margin-left: 0;
  }
`
